import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CognitoService } from 'src/app/core/services/cognito/cognito.service';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { RestCallService } from 'src/app/core/services/rest-call/rest-call.service';
import { TranslateApplicationService } from 'src/app/core/translate/translate-application-service';
import { IAppoitmentType } from '../Interfaces/IAppoitmentType';
import { ICostCenter } from '../Interfaces/ICostCenter';
import { IEmployee } from '../Interfaces/IEmployee';
import { IPosition } from '../Interfaces/IPosition';
import { IRelationship } from '../Interfaces/IRelationship';
import { IUsers } from '../Interfaces/IUsers';
import { IRole } from '../Interfaces/IRole';
import { TransactionResult } from 'src/app/core/services/interfaces/transaction-result';

@Injectable({
	providedIn: 'root',
})
export class EmployeeService extends RestCallService {
	data: IEmployee = {
		pkemployee: 0,
		employeename: '',
		employeenumber: 0,
		fkcostcenter: '',
		fkcell: 0,
		fksubarea: '',
		fkposition: '',
		fkshift: '',
		supervisornumber: 0,
		supervisorname: '',
		fkemployeetype: '',
		costCenterName: '',
		positionName: '',
		username: '',
		lastupdated: new Date(),
		strlastupdated: '',
		email: '',
		available: false,
		badgenumber: 0,
		isBadge: false,
		screen: '',
	};
	dataUser: IUsers = {
		pkuser: 0,
		fkemployeenumber: 0,
		employeename: '',
		windowsuser: '',
		email: '',
		available: false,
		fkuserupdater: 0,
		userupdatername: '',
		lastupdated: new Date(),
		strlastupdated: '',
		isBadge: false,
		username: '',
		editable: false,
		fkrole: 0,
		role: '',
		fksite: 0,
		siteshortname: '',
		isAdmin: false,
	};
  //private _role:string;
  /*set role(role:string){
    this._role = role
  }
  get role(){
    return this._role
  }*/
	constructor(
		public httpClient: HttpClient,
		private cognito: CognitoService,
		public toastr: ToastrService,
		public notificationService: NotificationService,
		public translate: TranslateApplicationService,
	) {

		super(
			httpClient,
			'APIMedicApp',
			toastr,
			notificationService,
			translate,
		);
    /*this.UserHasPermission().subscribe(
      (result: TransactionResult<IUsers[]>) => {
    if (result.success) {
      if (result.data.length > 0) {
        this.role = result.data[0].role
      }
    }
  },
  (error: any) => {
  },
  );*/
	}

	public GetListAdmin() {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		return this.get<IUsers[]>('/api/Employees/GetAdmins');
	}

	//EMPLOYEES
	public GetListEmployees() {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		return this.get<IEmployee[]>('/api/Employees/GetEmployees');
	}

	public GetInfoEmployees(username?:string) {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		if(window.localStorage.getItem('isbadge') != null){
			this.data.isBadge = true;
			this.data.username = window.localStorage.getItem('username');
		}
    if(username)  this.data.username = username;

		return this.post<IEmployee[]>('/api/Employees/GetEmployeInformartion', this.data);
	}

  public GetEmployeeInfo(username:string) {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		if(window.localStorage.getItem('isbadge') != null){
			this.data.isBadge = true;
		}
    let otheremployeedata: IEmployee = this.data;
    otheremployeedata.username = username
		return this.post<IEmployee[]>('/api/Employees/GetEmployeInformartion', otheremployeedata);
	}

	public GetCostCenter(costCenter: string) {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		return this.post<ICostCenter[]>('/api/Employees/GetCostCenter?costCenterNumber=' + costCenter);
	}

	public GetPosition(position: string) {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		return this.post<IPosition[]>('/api/Employees/GetPosition?positionNumber=' + position);
	}

	public GetRelationship() {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		return this.get<IRelationship[]>('/api/Employees/GetRelationship');
	}

	public EmployeeHasPermissionToAdminScreen() {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		if(window.localStorage.getItem('isbadge') != null){
			this.data.isBadge = true;
			this.data.username = window.localStorage.getItem('username');
		}

		return this.post<IEmployee[]>('/api/Employees/EmployeeHasPermissionToAdminScreen', this.data);
	}

	public ChangeStatusCategory(data: any) {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		if(window.localStorage.getItem('isbadge') != null){
			this.dataUser.username = window.localStorage.getItem('username')
			this.dataUser.isBadge = true;
		}

		this.dataUser.pkuser = data.pkuser;
		this.dataUser.available = data.available;
		return this.put('/api/Employees/ChangeStatusCategory', this.dataUser);
	}

	public CreateEmployee(data: any) {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		if(window.localStorage.getItem('isbadge') != null){
			this.dataUser.username = window.localStorage.getItem('username')
			this.dataUser.isBadge = true;
		}

		this.dataUser.employeename = data.txtEmployeeName;
		this.dataUser.fkemployeenumber = data.txtEmployeeNumber;
		this.dataUser.windowsuser = data.txtUsername;
		this.dataUser.email = data.txtEmail;
		this.dataUser.available = true;

		return this.post('/api/Employees/CreateEmployee', this.dataUser);
	}

	public GetBadge(data: any) {
		if(window.localStorage.getItem('isbadge') != null){
			this.data.isBadge = true;
			this.data.username = window.localStorage.getItem('username');
		}

		this.data.badgenumber = data.badgenumber;
		return this.post<IEmployee[]>('/api/Employees/GetBadge', this.data);
	}

	public GetUsers() {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		return this.get<IUsers[]>('/api/User/GetUsers');
	}

	public GetUsersPerSite(site:number){
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		return this.post<IUsers[]>('/api/User/GetUsersPerSite?fksite='+site);
	}

	public GetRoles(){
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		return this.get<IRole[]>('/api/Roles/GetRoles');
	}

	public UserHasPermission(){
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		if(window.localStorage.getItem('isbadge') != null){
			this.data.isBadge = true;
			this.data.username = window.localStorage.getItem('username');
		}

		return this.post<IUsers[]>('/api/User/UserHasPermission', this.data);
	}

	public CheckPermissions(screen: string) {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		if(window.localStorage.getItem('isbadge') != null){
			this.data.isBadge = true;
			this.data.username = window.localStorage.getItem('username');
		}
		this.data.screen = screen;

		return this.post<IUsers[]>('/api/User/CheckPermissions', this.data);
	}

	public CreateUser(data: any){
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		if(window.localStorage.getItem('isbadge') != null){
			this.dataUser.username = window.localStorage.getItem('username')
			this.dataUser.isBadge = true;
		}

		this.dataUser.employeename = data.txtEmployeeName;
		this.dataUser.fkemployeenumber = data.txtEmployeeNumber;
		this.dataUser.windowsuser = data.txtUsername;
		this.dataUser.email = data.txtEmail;
		this.dataUser.fkrole = data.selectRole;
		this.dataUser.fksite = data.selectedSite;
		this.dataUser.available = true;

		return this.post('/api/User/CreateUser', this.dataUser);
	}

	public UpdateUser(data: IUsers){
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		if(window.localStorage.getItem('isbadge') != null){
			this.dataUser.username = window.localStorage.getItem('username')
			this.dataUser.isBadge = true;
		}

		this.dataUser.pkuser = data.pkuser;
		this.dataUser.fkrole = data.fkrole
		this.dataUser.fksite = data.fksite;

		return this.post('/api/User/UpdateUser', this.dataUser);
	}

	public GetPositions(){
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		return this.get<IPosition[]>('/api/Employees/GetPositions');
	}
}
