import { MenuIntemInterface } from './interfaces/menu-item.interface';
import { MenuCollapseInterface } from './interfaces/menu-collapse.interface';
import { MenuGroupInterface } from './interfaces/menu-group.interface';

export class AppNavigationModel {
	public model: any[];

	constructor() {
		this.model = [
			{
				id: 'applications',
				title: 'Applications',
				translate: 'MedicApp',
				type: 'group',
				icon: 'apps',
				children: [
					{
						id: 'Home',
						title: 'Home',
						translate: 'NAV.Home',
						type: 'item',
						url: '/main/home',
						icon: 'home',
					},
					{
						id: 'dashboards',
						title: 'Dashboards',
						translate: 'NAV.Appointments',
						type: 'collapse',
						icon: 'schedule',
						children: [
							{
								id: 'Employees',
								title: 'Employees',
								translate: 'NAV.Employees',
								type: 'item',
								url: '/main/Employee',
								icon: 'person',
							},
							{
								id: 'Familiar',
								title: 'Familiar',
								translate: 'NAV.Familiar',
								type: 'item',
								url: '/main/Familiar',
								icon: 'family_restroom',
							},
							{
								id: 'ILCandidate',
								title: 'ILCandidate',
								translate: 'HOME.ILCandidate',
								type: 'item',
								url: '/main/ILCandidate',
								icon: 'remember_me',
							},
              {
								id: 'Specialty',
								title: 'Specialty',
								translate: 'NAV.Specialty',
								type: 'item',
								url: '/main/SpecialtyAppointment',
								icon: 'health_and_safety',
							},
						],
					},
					{
						id: 'Catalogs',
						title: 'Catalogs',
						translate: 'NAV.Catalogs',
						type: 'collapse',
						icon: 'dashboard',
						children: [
							{
								id: 'DateBlocking',
								title: 'Date Blocking',
								translate: 'NAV.Date_blocking',
								type: 'item',
								url: '/main/DateBlocking',
								icon: 'alarm_off',
							},
							{
								id: 'Users',
								title: 'Users',
								translate: 'NAV.Users',
								type: 'item',
								url: '/main/Users',
								icon: 'people_alt',
							},
							{
								id: 'Sites',
								title: 'Sites',
								translate: 'NAV.Sites',
								type: 'item',
								url: '/main/sites',
								icon: 'apartment',
							},
							{
								id: 'Campuses',
								title: 'Campuses',
								translate: 'Campuses',
								type: 'item',
								url: '/main/campuses',
								icon: 'domain',
							},
							{
								id: 'Buildings',
								title: 'Buildings',
								translate: 'NAV.Buildings',
								type: 'item',
								url: '/main/buildings',
								icon: 'home_work',
							},
							{
								id: 'Medical Units',
								title: 'Medical Units',
								translate: 'NAV.Medical_units',
								type: 'item',
								url: '/main/medical-units',
								icon: 'medical_services',
							},
							{
								id: 'Medical Offices',
								title: 'Medical Offices',
								translate: 'NAV.Medical_offices',
								type: 'item',
								url: '/main/medical-offices',
								icon: 'home_health',
							},
							{
								id: 'DistributionLists',
								title: 'Distribution Lists',
								translate: 'NAV.DistributionLists',
								type: 'item',
								url: '/main/DistributionLists',
								icon: 'contact_mail',
							},
							{
								id: 'Specialties',
								title: 'Specialties',
								translate: 'NAV.Specialties',
								type: 'item',
								url: '/main/Specialties',
								icon: 'local_hospital',
							},
							{
								id: 'MethodsForBooking',
								title: 'Methods For Booking',
								translate: 'NAV.MethodsForBooking',
								type: 'item',
								url: '/main/MethodsForBooking',
								icon: 'edit_square',
							},
							{
								id: 'CIE-10',
								title: 'CIE-10',
								translate: 'CIE-10',
								type: 'item',
								url: '/main/CIE-10',
								icon: 'vaccines',
							},
							{
								id: 'Tests',
								title: 'Tests',
								translate: 'NAV.Tests',
								type: 'item',
								url: '/main/Tests',
								icon: 'fact_check',
							},
							{
								id: 'Questions',
								title: 'Questions',
								translate: 'NAV.Questions',
								type: 'item',
								url: '/main/Questions',
								icon: 'quiz',
							},
							{
                                id: 'EmailFormat',
                                title: 'EmailFormat',
                                translate: 'NAV.Email_formats',
                                type: 'item',
                                url: '/main/EmailFormats',
                                icon: 'mark_as_unread',
                            },
						],
					},
					{
						id: 'MedicalPersonnel',
						title: 'Medical Personnel',
						translate: 'NAV.Medical_personnel',
						type: 'collapse',
						icon: 'medical_information',
						children: [
							{
								id: 'AgendaAdministration',
								title: 'Agenda Administration',
								translate: 'NAV.Medical_office_agenda',
								type: 'item',
								url: '/main/AgendaAdministration',
								icon: 'event_note',
							},
						],
					},
					{
						id: 'About',
						title: 'About',
						translate: 'NAV.About',
						type: 'collapse',
						icon: 'help',
						children: [
							{
								id: 'Contact',
								title: 'Contact',
								translate: 'NAV.Contact_title',
								type: 'item',
								url: '/main/contact',
								icon: 'phone',
							},
							{
								id: 'Releases',
								title: 'Last release',
								translate: 'NAV.Last_release',
								type: 'item',
								url: '/main/lastrelease',
								icon: 'refresh',
							},
						],
					},
				],
			},

		];
	}
}
