import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '../services/notification/notification.service';
import { TranslateService } from '../services/translate-service';
import { TransactionResult } from '../services/interfaces/transaction-result';
import { RestCallOriginService } from '../services/rest-call/rest-call-origin.service';

export let self: TranslateApplicationService = null;

@Injectable({
  providedIn: 'root'
})
export class TranslateApplicationService extends RestCallOriginService {
  data: any = {};
  currentLang: string;
  

  constructor(
    public httpClient: HttpClient
    , public toastr: ToastrService
    , public notificationService: NotificationService,
    public translate: TranslateService) {
    super(httpClient, 'TranslateService', toastr, notificationService, translate);
  }

  use(lang: any): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      if (window["applicationId"] == "")
        this.useLocal(lang.flag, {}, resolve);
      else {
        const langPath = `${(<any>window).configuration.enviroment["TranslateService"]}applications/${lang.applicationId}/languages/${lang.id}/translations`;
        this.http.get<any>(langPath).subscribe(
          (translation: TransactionResult<any>) => {
            if (translation)
              this.useLocal(lang.flag, translation, resolve);
            else
              this.useLocal(lang.flag, {}, resolve);
          },
          error => {
            this.useLocal(lang.flag, {}, resolve);
          }
        );
      }
    });

  }

  private useLocal(lang: string, languageRemote: any, resolve: any) {
    let fileName;

    if (lang.split('-').length > 0)
      fileName = lang.split('-')[0];
    else
      fileName = lang;

    const langPath = `assets/i18n/${fileName || 'en'}.json`;
    this.http.get<{}>(langPath).subscribe(
      translation => {
        this.data = Object.assign({}, translation || {}, languageRemote || {});
        resolve(this.data);
      },
      error => {
        this.data = Object.assign({}, languageRemote || {});
        resolve(this.data);
      }
    );
  }

  translateKey(key: string) {
    if (key.split(".").length == 1)
      return this.data[key] || key;
    else {
      let arrayKey: any;
      arrayKey = key.split(".");
      let valueFind: any = "";

      let index: number = 0;

      for (let item of arrayKey) {
        if (index == 0)
          valueFind = this.data[item];
        else {
          valueFind = valueFind == undefined ? key : valueFind[item];
        }

        index++;
      }
      return valueFind;
    }
  }

}
